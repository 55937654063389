/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface CanvasLimit { 
    bid?: number;
    titleBefore?: string;
    titleAfter?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
}

