/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NotificationTypeFields } from './notificationTypeFields';
import { GreetingFilterOrder } from './greetingFilterOrder';


export interface NotificationTypeFilter { 
    offset?: number;
    limit?: number;
    skip?: number;
    order?: GreetingFilterOrder;
    where?: { [key: string]: any; };
    fields?: NotificationTypeFields;
}

