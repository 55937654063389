/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: AuctionTR, schemaOptions: { includeRelations: false })
 */
export interface AuctionTR { 
    id?: number;
    auctionNumber?: string;
    auctionName: string;
    descriptionShort?: string;
    description?: string;
    internetAuction?: boolean;
    exchangeRate?: number;
    visibleFrom?: string;
    visibleTo?: string;
    limitationFrom?: string;
    limitationTo?: string;
    auctionFrom?: string;
    timeLimit?: number;
    picUrl?: string;
    idStatus?: number;
    statusCode?: string;
    status?: string;
    idCurrentClosingItem?: number;
    fee?: number | null;
    streamLinkActive?: string | null;
    streamLinkYouTube?: string | null;
    streamLinkWowza?: string | null;
    biddingTableUrlcs?: string;
    auctionNoticeUrlcs?: string;
    biddingTableUrlen?: string;
    auctionNoticeUrlen?: string;
    isMainAuction: boolean;
    last_modified?: string;
    isPaused?: boolean;
    hasStarted?: boolean;
    items?: Array<any>;
}

