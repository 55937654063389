/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GreetingFilterOrder } from './greetingFilterOrder';
import { FinContractTemplateFields } from './finContractTemplateFields';


export interface FinContractTemplateFilter { 
    offset?: number;
    limit?: number;
    skip?: number;
    order?: GreetingFilterOrder;
    fields?: FinContractTemplateFields;
}

