/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface UserSession { 
    id?: string;
    language?: string;
    idCountry?: number;
    isApproved?: boolean;
    isReviewer?: boolean;
    firstName?: string;
    lastName?: string;
    email?: string;
    waitingForApproval?: boolean;
    deniedApproval?: boolean;
}

