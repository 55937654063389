/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserWithRelations } from './myUserWithRelations';
import { ApprovalRequestWithRelations } from './approvalRequestWithRelations';


/**
 * (tsType: CampaignWithRelations, schemaOptions: { includeRelations: true })
 */
export interface CampaignWithRelations { 
    id: string;
    title: string;
    code: string;
    dateStart?: string | null;
    dateEnd?: string | null;
    active: boolean;
    partnerId?: string | null;
    approvalRequests?: Array<ApprovalRequestWithRelations>;
    partner?: MyUserWithRelations;
    foreignKey?: any | null;
}

