/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: UserWs, schemaOptions: { includeRelations: false })
 */
export interface UserWs { 
    userId: string;
    name: string;
    connected?: string;
    admin?: boolean;
    rooms?: Array<string>;
}

