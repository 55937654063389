/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemClosingStatus } from './itemClosingStatus';
import { Bid } from './bid';


export interface ItemClosingRes { 
    status?: ItemClosingStatus;
    availableBids?: Array<Bid>;
}

