/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApprovalRequestPartialWithRelations } from './approvalRequestPartialWithRelations';
import { MyUserPartialWithRelations } from './myUserPartialWithRelations';


/**
 * (tsType: Partial<CampaignWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface CampaignPartialWithRelations { 
    id?: string;
    title?: string;
    code?: string;
    dateStart?: string | null;
    dateEnd?: string | null;
    active?: boolean;
    partnerId?: string | null;
    approvalRequests?: Array<ApprovalRequestPartialWithRelations>;
    partner?: MyUserPartialWithRelations;
    foreignKey?: any | null;
}

