/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GreetingFilterOrder } from './greetingFilterOrder';
import { MyUserScopeFilterFields } from './myUserScopeFilterFields';


export interface MyUserScopeFilter { 
    offset?: number;
    limit?: number;
    skip?: number;
    order?: GreetingFilterOrder;
    where?: { [key: string]: any; };
    fields?: MyUserScopeFilterFields;
    include?: Array<{ [key: string]: any; }>;
}

