/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyBiddingLog } from './myBiddingLog';
import { MyBidding } from './myBidding';
import { ApprovalRequest } from './approvalRequest';
import { ItemFrontendWinModel } from './itemFrontendWinModel';
import { MyClosing } from './myClosing';
import { UserDoc } from './userDoc';


export interface UserDetailAdmin { 
    id?: string;
    regNumber?: number;
    email: string;
    titleBefore?: string;
    firstName?: string;
    lastName?: string;
    birthday?: string;
    titleAfter?: string;
    phone?: string;
    street1?: string;
    street2?: string;
    city?: string;
    idCountry?: number;
    country?: string;
    zipCode?: string;
    language?: string;
    registrationDate?: string;
    consent1?: boolean;
    consent2?: boolean;
    hasNewsletter?: boolean;
    id1Approved?: boolean;
    id2Approved?: boolean;
    bankAccApproved?: boolean;
    isApproved?: boolean;
    isAdmin?: boolean;
    idGender?: number;
    gender?: string;
    idGreeting?: number;
    greeting?: string;
    bankAccNumber?: string;
    comment?: string;
    doc1Number?: string;
    doc1ValidTill?: string;
    doc1Issuer?: string;
    doc1IdDocType?: number;
    doc1DocType?: string;
    doc2Number?: string;
    doc2ValidTill?: string;
    doc2Issuer?: string;
    doc2IdDocType?: number;
    doc2DocType?: string;
    idStatus?: number;
    company?: string;
    ic?: string;
    dic?: string;
    mailAuctionInfo?: boolean;
    mailAuctionAlert?: boolean;
    notificationSystemWhatsApp?: boolean;
    notificationSystemMail?: boolean;
    notificationAuctionInfoWhatsApp?: boolean;
    notificationAuctionInfoMail?: boolean;
    notificationNewsletterWhatsApp?: boolean;
    notificationNewsletterMail?: boolean;
    notificationFollowedAuthorWhatsApp?: boolean;
    notificationFollowedAuthorMail?: boolean;
    notificationLikedItemWhatsApp?: boolean;
    notificationLikedItemMail?: boolean;
    isReviewer?: boolean;
    canvasId?: number;
    allowUploadToCanvas: boolean;
    auth2FAEnabled?: boolean;
    documents?: Array<UserDoc>;
    approvalRequests?: Array<ApprovalRequest>;
    myWins?: Array<ItemFrontendWinModel>;
    myBiddings?: Array<MyBidding>;
    myClosings?: Array<MyClosing>;
    myBiddingLog?: Array<MyBiddingLog>;
    hasDocument?: boolean;
}

