/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApprovalRequest } from './approvalRequest';
import { UserDoc } from './userDoc';


/**
 * (tsType: UserDetailTRWithRelations, schemaOptions: { includeRelations: true })
 */
export interface UserDetailTRWithRelations { 
    id?: string;
    regNumber?: number;
    email: string;
    titleBefore?: string;
    firstName?: string;
    lastName?: string;
    birthday?: string;
    titleAfter?: string;
    phone?: string;
    street1?: string;
    street2?: string;
    city?: string;
    idCountry?: number;
    country?: string;
    zipCode?: string;
    language?: string;
    registrationDate?: string;
    consent1?: boolean;
    consent2?: boolean;
    hasNewsletter?: boolean;
    id1Approved?: boolean;
    id2Approved?: boolean;
    bankAccApproved?: boolean;
    isApproved?: boolean;
    isAdmin?: boolean;
    idGender?: number;
    gender?: string;
    idGreeting?: number;
    greeting?: string;
    bankAccNumber?: string;
    doc1Number?: string;
    doc1ValidTill?: string;
    doc1Issuer?: string;
    doc1IdDocType?: number;
    doc1DocType?: string;
    doc2Number?: string;
    doc2ValidTill?: string;
    doc2Issuer?: string;
    doc2IdDocType?: number;
    doc2DocType?: string;
    company?: string;
    ic?: string;
    dic?: string;
    mailAuctionInfo?: boolean;
    isReviewer?: boolean;
    canvasId?: number;
    allowUploadToCanvas: boolean;
    documents?: Array<UserDoc>;
    approvalRequests?: Array<ApprovalRequest>;
    hasDocument?: boolean;
}

