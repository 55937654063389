/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApprovalRequestExcludingIdWithRelations } from './approvalRequestExcludingIdWithRelations';
import { MyUserExcludingIdWithRelations } from './myUserExcludingIdWithRelations';


/**
 * (tsType: Omit<CampaignWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface CampaignExcludingIdWithRelations { 
    title: string;
    code: string;
    dateStart?: string | null;
    dateEnd?: string | null;
    active: boolean;
    partnerId?: string | null;
    approvalRequests?: Array<ApprovalRequestExcludingIdWithRelations>;
    partner?: MyUserExcludingIdWithRelations;
    foreignKey?: any | null;
}

