/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: AuctionCheck, schemaOptions: { includeRelations: false })
 */
export interface AuctionCheck { 
    id: number;
    idStatus: number;
    limitationFrom?: string;
    limitationTo?: string;
    auctionFrom?: string;
}

