/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyBidding } from './myBidding';
import { ItemFrontendWinModel } from './itemFrontendWinModel';
import { MyClosing } from './myClosing';


export interface UserInfo { 
    myWins?: Array<ItemFrontendWinModel>;
    myBiddings?: Array<MyBidding>;
    myClosings?: Array<MyClosing>;
}

