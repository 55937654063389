/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GreetingFilterOrder } from './greetingFilterOrder';
import { CountryFields } from './countryFields';


export interface CountryFilter { 
    offset?: number;
    limit?: number;
    skip?: number;
    order?: GreetingFilterOrder;
    where?: { [key: string]: any; };
    fields?: CountryFields;
}

