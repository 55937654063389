/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CanvasLimit } from './canvasLimit';


export interface CanvasHighestLimit { 
    canvasItemId?: number;
    limit: CanvasLimit;
}

