import {Injectable} from '@angular/core';
// @ts-ignore
import { io } from 'socket.io-client';
import {first, firstValueFrom, Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../../environments/environment';
import {PrimeIcons} from 'primeng/api';
import {ItemClosingControllerService, ItemClosingService, ItemControllerService} from '../../../@backend';

@Injectable({
  providedIn: 'root'
})
export class LiveBiddingService {
  socket: any;
  token: string;
  socketId: string;
  connectedUsers: number = 0;

  constructor(private authService: AuthenticationService, private itemClosingService: ItemClosingControllerService) {
    this.token = this.authService.currentUser.token;
    this.socketId = null;
  }


  setupSocketConnection(namespace: string): any {
    if (this.socketId === null){
      this.socket = io(namespace, { query: {token: this.token, admin: '1'}, withCredentials: !environment.isLocal });
      this.getOnlineUsers();
      return {status: true, state: 'conn-new'};
    }else{
      return {status: true, state: 'conn-exists', data: {connectedUsers: this.connectedUsers}};
    }

  }

  onNewBid(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('bidding', (bid) => {
        observer.next(bid);
      });
    });
  }

  onAucEvent(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('auc-event', (bid) => {
        observer.next(bid);
      });
    });
  }

  onUserConnectedEvent(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('user-connected', () => {
        this.connectedUsers += 1;
        observer.next(this.connectedUsers);
      });
    });
  }

  onUserDisconnectEvent(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('user-disconnect', () => {
        this.connectedUsers -= 1;
        observer.next(this.connectedUsers);
      });
    });
  }

  onReconnection(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('reconnect_attempt', () => {
        observer.next();
      });
    });
  }

  onDisconnect(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('disconnect', () => {
        // this.socketId = null;
        console.log('disconnect');
        observer.next();
      });
    });
  }

  onReconnect(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('reconnect', () => {
        observer.next();
      });
    });
  }

  onConnected(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('connect', () => {
        this.socketId = this.socket.id;
        observer.next();
      });
    });
  }


  async getOnlineUsers(): Promise<void> {
    const onlineUsers = await firstValueFrom(this.itemClosingService.itemClosingControllerAdminGetOnlineUsersCount(1));
    this.connectedUsers = onlineUsers.users;
  }

  // addBid(bid: unknown): void {
  //   this.socket.emit('bidding', 1000);
  // }
}
